import { Injectable } from '@angular/core';
import { AppSettings } from './app-settings.model';
import { BuildSettings } from './build-settings.model';

@Injectable({ providedIn: 'root' })
export class SettingsService {

  public settings: AppSettings;

  public buildSettings: BuildSettings;

  constructor() {
    this.settings = new AppSettings();
    this.buildSettings = new BuildSettings();
  }
}
