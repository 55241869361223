<header class="app-header navbar">
  <a class="navbar-brand" href="/"></a>
  <ul class="nav navbar-nav ml-auto">
    <li [hidden]="!showSpinner" class="nav-item"><i class="fa fa-spinner fa-spin"></i></li>
    <li class="nav-item">Hallo {{userDisplayName}}!</li>
    <li class="nav-item">
      <a class="nav-link clickable" (click)="logout()" role="button">
        <span>Abmelden</span>
      </a>
    </li>
  </ul>
</header>
