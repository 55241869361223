import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {UserDataService} from '../../services/user-data/user-data.service';

@Component({
  selector: 'ebd-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  public userName: string;
  public password: string;
  public rememberMe: boolean;
  public showFailure: boolean;
  private subscription: Subscription;

  constructor(private userService: UserDataService,
              private router: Router) {
  }

  public ngOnInit(): void {
    this.subscription = this.userService.user$.subscribe(
      user => {
        if (this.userService.loginReturnUrl) {
          this.router.navigateByUrl(this.userService.loginReturnUrl);
          this.userService.loginReturnUrl = undefined;
        } else {
          this.router.navigateByUrl('/');
        }
      },
      err => {
        this.showFailure = true;
      });
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public login(): void {
    this.userService.login(this.userName, this.password, this.rememberMe);
  }
}
