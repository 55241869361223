import {Component, OnDestroy, OnInit} from '@angular/core';

import {BookOrderDataService} from '../../services/book-order-data/book-order-data.service';
import {BusyService} from '../../services/busy.service';
import {IBookOrderModel} from '../../models/book-order.model';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { SettingsService } from '../../core/config/settings.service';
import { BooksHttpService } from '../../services/books/books-http.service';

interface ISumByProductCode {
  bookProductId: string;
  sumOrderedBooks: number;
  sumPositionNetPrice: number;

  addBookOrder(order: IBookOrderModel);
}

class SumByProductCode implements ISumByProductCode {
  bookProductId: string = '';
  sumOrderedBooks: number = 0;
  sumPositionNetPrice: number = 0;

  constructor() {
  }

  addBookOrder(order: IBookOrderModel) {
    if (this.bookProductId && this.bookProductId !== order.bookProductId) {
      return false;
    }
    this.bookProductId = order.bookProductId;
    this.sumOrderedBooks += order.quantity;
    this.sumPositionNetPrice += order.positionNetPrice;
  }

}

@Component({
  selector: 'ebd-book-orders',
  templateUrl: './book-orders.component.html',
  styleUrls: ['./book-orders.component.scss']
})
/**
 * Filters and Display a list of book orders (IBookOrderModel)
 */
export class BookOrdersComponent implements OnInit, OnDestroy {
  /**
   * Array of book orders
   * @type {IBookOrderModel[]}
   */
  public bookOrders: IBookOrderModel[] = [];

  /**
   * Sum of ordered books
   * @type {number}
   */
  public sumOrderedBooks: number = 0;

  /**
   * Sum of the net price of each position
   * @type {number}
   */
  public sumPositionNetPrice: number = 0;

  /**
   * Array of objects that holds the sum of ordered books and their net price for a specific product code
   * @type {ISumByProductCode[]}
   */
  public sumsByProductCode: ISumByProductCode[] = [];

  /**
   * The month for which the data should be retrieved
   * @type {number}
   */
  public month: number;

  /**
   * The year for which the data should be retrieved
   * @type {number}
   */
  public year: number;

  /**
   * Array of years to chose of in the filter
   * @type {number[]}
   */
  public yearRange: number[] = [];

  /**
   * Filter value that indicates, if the order as a connection to a mortician
   * @type {string}
   */
  public companyOrigin: string = 'all';

  /**
   * Observable of the user search input
   * @type {Subject<string>}
   */
  public searchTermUser$ = new Subject<string>();

  /**
   * Observable of the company search input
   * @type {Subject<string>}
   */
  public searchTermCompany$ = new Subject<string>();

  /**
   * Holds the actual search term for filtering by users
   * @type {string}
   */
  public username: string;

  /**
   * Holds the actual search term for filtering by company
   * @type {string}
   */
  public companyname: string;

  /**
   * Holds the value of the customer group select
   * matches the last 3 characters of the bookOrder.productId
   * @type {string}
   */
  public productIdEnding: string = '';

  /**
   * Subscribes for new bookOrders
   * @type {Subscription}
   */
  private orderSubscription: Subscription;

  /**
   * Subscribes for new user search input
   * @type {Subscription}
   */
  private userSearchSubscription: Subscription;

  /**
   * Subscribes for new company search input
   * @type {Subscription}
   */
  private companySearchSubscription: Subscription;

  /**
   * Holds the base path of the pdf links, based on the environment apiHostName
   * @Type {string}
   */
  public projectViewBasePath = '';

  /**
   * @param bookOrderDataService Service that handles book orders
   * @param busyService service that handles the busy indicator
   * @param settingsService service that handles application settings
   */
  constructor(private bookOrderDataService: BookOrderDataService,
    private busyService: BusyService,
    private settingsService: SettingsService,
    private booksHttpService: BooksHttpService) {
  }

  /**
   * Initialises the component
   * Sets initial values for component properties and
   * subscribes to filter input and book orders
   */
  public ngOnInit(): void {
    this.projectViewBasePath = this.settingsService.settings.apiHostName + '/api/books/view';
    this.month = new Date().getMonth() + 1;
    this.year = new Date().getFullYear();
    this.yearRange = new Array(this.year - 2017 + 1).fill(1).map((val, idx) => 2017 + idx);

    this.orderSubscription = this.bookOrderDataService.bookOrders$
      .pipe(
        map((bookOrders: IBookOrderModel[]) => {
          return bookOrders.filter(bo => bo.bookProductId.match(`${this.productIdEnding}$`));
        })
      )
      .subscribe(
        bookOrders => {
          this.busyService.hide();

          const allSums: Map<string, ISumByProductCode> = new Map();
          this.bookOrders = bookOrders.map(bo => {
            this.sumOrderedBooks += bo.quantity;
            this.sumPositionNetPrice += bo.positionNetPrice;
            const productCodeGroup: ISumByProductCode = allSums.get(bo.bookProductId) || new SumByProductCode();
            productCodeGroup.addBookOrder(bo);
            allSums.set(bo.bookProductId, productCodeGroup);
            return bo;
          });
          allSums.forEach(sumItem => {
            this.sumsByProductCode.push(sumItem);
          });
        },
        err => {
          console.error(err);
        }
      );

    this.userSearchSubscription =
      this.searchTermUser$.pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe(
        sTerm => {
          this.username = sTerm;
          this.busyService.show();
          this.loadFilteredBookOrders();
        }
      );

    this.companySearchSubscription =
      this.searchTermCompany$
      .pipe(
        debounceTime(400),
        distinctUntilChanged()
      ).subscribe(
        sTerm => {
          this.companyname = sTerm;
          this.busyService.show();
          this.loadFilteredBookOrders();
        }
      );

    this.loadFilteredBookOrders();
  }

  /**
   * Unsubscribe each subscription when component ist destroyed
   */
  public ngOnDestroy(): void {
    if (this.orderSubscription) {
      this.orderSubscription.unsubscribe();
    }
    if (this.userSearchSubscription) {
      this.userSearchSubscription.unsubscribe();
    }
    if (this.companySearchSubscription) {
      this.companySearchSubscription.unsubscribe();
    }
  }

  /**
   * Triggers the load of new book orders by actual filter params
   * and reset aggregate properties
   */
  public loadFilteredBookOrders(): void {
    this.busyService.show();
    this.sumOrderedBooks = 0;
    this.sumPositionNetPrice = 0;
    this.sumsByProductCode = [];
    this.bookOrderDataService.loadBookOrders(
      this.year,
      this.month,
      this.username,
      this.companyname,
      this.companyOrigin);
  }

  public isRapid(order: IBookOrderModel): boolean {
    return !!order.bookProductId.match('-03$');
  }

  public isDependant(order: IBookOrderModel): boolean {
    return !!order.bookProductId.match('-01$');
  }

  public isReservationProcessed(order: IBookOrderModel): boolean {
    return !!order.orderTimestamp;
  }
  public isReservationInProcess(order: IBookOrderModel): boolean {
    return !order.orderTimestamp && !order.orderReportedTimestamp;
  }

  public isReservationUnprocessed(order: IBookOrderModel): boolean {
    return !order.orderTimestamp && !!order.orderReportedTimestamp;
  }

  public copyToClipboard(content: string): void {
    navigator.clipboard.writeText(content);
  }

  public getOrderStateTooltip(order: IBookOrderModel): string {
    if (this.isReservationProcessed(order)) return 'Die Bestellung ist in die Produktion gegeben worden.';
    if (this.isReservationInProcess(order)) return 'Die Bestellung ist in Arbeit.';
    if (this.isReservationUnprocessed(order)) return 'Die Bestellung ist immer noch nicht bearbeitet worden. Bitte kümmere dich darum.';

    return 'oops...';
  }

  public loadProjectView(bookPk: number): void {
    this.booksHttpService.getProjectView(bookPk)
      .subscribe(response => {
        window.open(
          response.url,
          '_blank'
        );
    });
  }
}
