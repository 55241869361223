import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { ProvisionedBookModel } from '../../models/provisioned-book.model';

import { BookOrderDataService } from '../../services/book-order-data/book-order-data.service';

@Component({
  selector: 'ebd-provisioned-books',
  templateUrl: './provisioned-books.component.html',
  styleUrls: ['./provisioned-books.component.scss']
})
export class ProvisionedBooksComponent implements OnInit, OnDestroy {
  /**
   * Contains the sum of all displayed books' #netTotal.
   */
  public sumNetTotal: number;

  /**
   * Contains the sum of all displayed books' #provision.
   */
  public sumProvision: number;

  /**
   * Contains the morticians, of whom one or more books are displayed with in the current time filter.
   */
  public selectableMorticians: string[];

  /**
   * Contains the start- and end-date of the currently selected time range.
   */
  private _dateRange: Date[];

  /**
   * Contains the name of the mortician, which is selected for filtering.
   */
  private _selectedMortician: string;

  /**
   * Contains the filtered list of books.
   */
  private _provisionedBooks: ProvisionedBookModel[];

  /**
   * Contains the list of books which are loaded via #dataService.
   */
  private loadedBooks: ProvisionedBookModel[];

  /**
   * Contains the subscription to the #dataService's books-observable.
   */
  private dataSubscription: Subscription;

  constructor(
    private dataService: BookOrderDataService) {
  }

  /**
   * Getter for #_provisionedBooks.
   * @returns {ProvisionedBookModel[]}
   */
  public get provisionedBooks(): ProvisionedBookModel[] {
    return this._provisionedBooks;
  }

  /**
   * Setter for #_provisionedBooks. Calculates the sums for netTotals and provisions.
   * @param {ProvisionedBookModel[]} value: The new value to set.
   */
  public set provisionedBooks(
    value: ProvisionedBookModel[]) {
    this._provisionedBooks = value;

    this.sumNetTotal = this._provisionedBooks
      .map((b: ProvisionedBookModel) => b.netTotal)
      .reduce((sum: number, netTotal: number) => sum + netTotal, 0);

    this.sumProvision = this._provisionedBooks
      .map((b: ProvisionedBookModel) => b.provision)
      .reduce((sum: number, provision: number) => sum + provision, 0);
  }

  /**
   * Getter for {@link _dateRange}.
   * @returns {Date[]}
   */
  public get dateRange(): Date[] {
    return this._dateRange;
  }

  /**
   * Setter for {@link _dateRange}. Triggers a reload of books with the modified time range.
   * @param {Date[]} value: The new value for the filtering time range.
   */
  public set dateRange(
    value: Date[]) {
    this._dateRange = value;
    this.refreshBooks();
  }

  /**
   * Getter for {@link _selectedMortician}.
   * @returns {string}
   */
  public get selectedMortician(): string {
    return this._selectedMortician;
  }

  /**
   * Setter for {@link _selectedMortician}. Triggers a reload of books with the modified selected mortician.
   * @param {string} value: The new value for the selected mortician.
   */
  public set selectedMortician(
    value: string) {
    this._selectedMortician = value;
    this.refreshBooks();
  }

  /**
   * Filters the #provisionedBooks by #seletcedMortician.
   */
  public filterBooks() {
    if (!this.selectedMortician) {
      this.provisionedBooks = [...this.loadedBooks];
      return;
    }

    const filteredBooks = this.loadedBooks
      .filter((b: ProvisionedBookModel) => b.morticianName === this.selectedMortician);

    this.provisionedBooks = [...filteredBooks];
  }

  /**
   * Updates the #isSettled property of a books reservation-item.
   * @param {ProvisionedBookModel} book: The reservation-item.
   */
  public updateProvisionSettlement(
    book: ProvisionedBookModel): void {
    this.dataService.changeSettlement(book.pk, book.isSettled);
  }

  /**
   * Subscribes to the #dataService's books and initiates a loading.
   */
  public ngOnInit(): void {
    this.dataSubscription = this.dataService.provisionedBooks$.subscribe((books: ProvisionedBookModel[]) => {
      this.loadedBooks = [...books];
      this.filterBooks();
      this.updateSelectableMorticians();
    });

    this.refreshBooks();
  }

  /**
   * Unsubscribes from the #dataService's books.
   */
  public ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  /**
   * Sets the #selectableMorticians from the #loadedBooks.
   */
  private updateSelectableMorticians() {
    const distinctNames: string[] = [];
    this.loadedBooks
      .map((b: ProvisionedBookModel) => b.morticianName)
      .forEach((name: string) => {
        if (!distinctNames.includes(name)) {
          distinctNames.push(name);
        }
      });

    this.selectableMorticians = distinctNames.sort();
  }

  /**
   * Initiates a loading of books filtered by the current time range.
   */
  private refreshBooks() {
    let minDate: Date = null;
    let maxDate: Date = null;

    if (this._dateRange && (this._dateRange.length === 2)) {
      minDate = this.dateRange[0];
      maxDate = this.dateRange[1];
    }

    this.dataService.loadProvisionedBooks(minDate, maxDate);
  }
}
