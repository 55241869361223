import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { CustomerModel } from '../../../models/customer-model';

@Component({
  selector: 'ebd-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss']
})
export class CustomersListComponent implements OnInit {

  @Input()
  public customers$: Observable<CustomerModel[]>;

  @Input()
  public areActionsEnabled = true;

  @Output()
  public customerEditing: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public customerDeleting: EventEmitter<CustomerModel> = new EventEmitter<CustomerModel>();

  constructor() { }

  ngOnInit(): void {
  }

  public editCustomer(customerPk: number) {
    this.customerEditing.emit(customerPk);
  }

  public deleteCustomer(customer: CustomerModel) {
    this.customerDeleting.emit(customer);
  }
}
