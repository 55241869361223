<div class="container">
  <div class="row header">
    <div class="col">Rapid ID</div>
    <div class="col">Debitoren-Nummer</div>
    <div class="col">Firma</div>
    <div class="col">Ort</div>
    <div class="col">Abrechnung</div>
    <div class="col"></div>
  </div>
  <div *ngFor="let c of customers$ | async" class="row body">
    <div class="col">{{c.rapidId}}</div>
    <div class="col">{{c.debtorId}}</div>
    <div class="col">{{c.name}}</div>
    <div class="col">{{c.town}}</div>
    <div class="col">{{c.billingType}}</div>
    <div class="col actions">
      <i *ngIf="areActionsEnabled" class="action-icon icon-pencil" (click)="editCustomer(c.pk)" tooltip="Sammelabrechner bearbeiten"></i>
      <i *ngIf="areActionsEnabled" class="action-icon icon-trash" (click)="deleteCustomer(c)" tooltip="Sammelabrechner löschen"></i>
    </div>
  </div>
</div>
