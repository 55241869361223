import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ebd-date-range-selector',
  templateUrl: './date-range-selector.component.html',
  styleUrls: ['./date-range-selector.component.scss']
})
export class DateRangeSelectorComponent {

  @Output()
  public dateRangeChange: EventEmitter<Date[]> = new EventEmitter<Date[]>();

  /**
   * Contains the start- and end-date of the currently selected time range.
   */
  private _dateRange: Date[];

  constructor() {
  }

  /**
   * Getter for {@link dateRange}.
   * @returns {Date[]}
   */
  public get dateRange(): Date[] {
    return this._dateRange;
  }

  /**
   * Setter for {@link dateRange}. Triggers a reload of books with the modified time range.
   * @param {Date[]} value: The new value for then filtering time range.
   */
  @Input()
  public set dateRange(
    value: Date[]) {
    this._dateRange = value;
    this.dateRangeChange.emit(this._dateRange);
  }
}
