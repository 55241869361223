export interface IUserModel {
  sub: string;
  name: string;
  token: string;
}

export class UserModel implements IUserModel {

  public token: string;

  constructor(public sub: string,
              public name: string) {
  }

  public static get empty(): IUserModel {
    return new UserModel('', '');
  }
}
