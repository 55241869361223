import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'ebd-string-validation',
  templateUrl: './string-validation.component.html',
  styleUrls: ['./string-validation.component.scss']
})
export class StringValidationComponent implements OnInit {

  @Input()
  public control: AbstractControl;

  constructor() { }

  ngOnInit(): void {
  }
}
