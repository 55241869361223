import {HttpClient} from '@angular/common/http';
import { SettingsService } from '../../../app/core/config/settings.service';

import {BookOrderDataHttpService} from './book-order-data-http.service';
import {BookOrderDataService} from './book-order-data.service';

export function userDataServiceFactory(client: HttpClient, settingsService: SettingsService) {
  return new BookOrderDataHttpService(client, settingsService);
}

export const bookOrderDataServiceProvider = {
  provide: BookOrderDataService,
  useFactory: userDataServiceFactory,
  deps: [HttpClient, SettingsService]
};
