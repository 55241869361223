<div *ngIf="control.invalid && (control.dirty || control.touched)" class="validation-error-container">
  <div *ngIf="control.errors?.['required']">
    Hier muss ein Wert angegeben werden.
  </div>
  <div *ngIf="control.errors?.['maxlength']">
    Es wurden zu viele Zeichen eingegeben.
  </div>
  <div *ngIf="control.errors?.['email']">
    Das ist keine korrekte E-Mail-Adresse.
  </div>
</div>
