import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import { SettingsHttpService } from './app/core/config/settings.http.service';

const appSettings = SettingsHttpService.getAppSettingsForJson();
if (appSettings.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
