import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SettingsService } from '../../../app/core/config/settings.service';

import { ActionTypes } from '../../models/action-descriptor';
import { IBookOrderModel } from '../../models/book-order.model';
import { InvoiceableBookModel } from '../../models/invoiceable-book.model';
import { ProvisionedBookModel } from '../../models/provisioned-book.model';

import { BookOrderDataService } from './book-order-data.service';

@Injectable()
export class BookOrderDataHttpService extends BookOrderDataService {
  private baseUrl;

  constructor(private client: HttpClient, settingsService: SettingsService) {
    super();
    this.baseUrl = `${settingsService.settings.apiHostName}/api/book-orders`;
  }

  private static formatDate(
    date: Date): string {
    if (date === null) {
      return null;
    }

    return `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`;
  }

  public loadBookOrders(
    year: number,
    month: number,
    username: string = '',
    companyname: string = '',
    companyOrigin: string = ''): void {

    const params = new HttpParams()
      .set('year', year.toString())
      .set('month', month.toString())
      .set('username', username)
      .set('companyname', companyname)
      .set('companyOrigin', companyOrigin);

    this.client.get<IBookOrderModel[]>(this.baseUrl, {params}).subscribe(
      bookOrders => {
        this.bookOrders$.next(bookOrders);
      }
    );
  }

  public loadProvisionedBooks(
    minDate?: Date,
    maxDate?: Date): void {
    const httpParams = new HttpParams()
      .set('minDate', BookOrderDataHttpService.formatDate(minDate))
      .set('maxDate', BookOrderDataHttpService.formatDate(maxDate));
    const url = this.baseUrl + '/provisioned-books';
    this.client.get<ProvisionedBookModel[]>(url, {params: httpParams}).subscribe((books) => {
      this.provisionedBooks$.next(books.map((b: ProvisionedBookModel) => {
        return {...b, isSettled: false};
      }));
    });
  }

  public changeSettlement(
    reservationItemId: number,
    isSettled: boolean): void {
    const url = this.baseUrl + `/provision-settlement/${reservationItemId}`;
    this.client.put<void>(url, {reservationItemId, isSettled}).subscribe(
      () => {
        this.completedAction$.next({actionType: ActionTypes.ChangeSettlement, payload: null});
      },
      (error: HttpErrorResponse) => {
        this.failedAction$.next({actionType: ActionTypes.ChangeSettlement, payload: error});
      });
  }

  public loadInvoiceableBooks(
    minDate?: Date,
    maxDate?: Date): void {
    const httpParams = new HttpParams()
      .set('minDate', BookOrderDataHttpService.formatDate(minDate))
      .set('maxDate', BookOrderDataHttpService.formatDate(maxDate));
    const url = this.baseUrl + '/invoiceable-books';
    this.client.get<InvoiceableBookModel[]>(url, {params: httpParams}).subscribe(
      (books) => {
        this.completedAction$.next({
          actionType: ActionTypes.LoadInvoiceableBooks, payload: books.map((b: InvoiceableBookModel) => {
            return {...b, isSettled: false};
          })
        });
      },
      (error: HttpErrorResponse) => {
        this.failedAction$.next({actionType: ActionTypes.LoadInvoiceableBooks, payload: error});
      });
  }
}
