import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {UserDataService} from '../../services/user-data/user-data.service';
import {Router} from '@angular/router';
import {BusyService} from '../../services/busy.service';

@Component({
  selector: 'ebd-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit, OnDestroy {
  public userDisplayName: string;
  public showSpinner: boolean = false;
  private subscription: Subscription;
  private busySubscription: Subscription;

  constructor(private userService: UserDataService,
              private busyService: BusyService,
              private router: Router) {
  }

  public ngOnInit():void {
    this.busySubscription = this.busyService.switch$.subscribe(
      (next) => {
        this.showSpinner = next;
      }
    );
    this.userDisplayName = this.userService.getUser().name;
    this.subscription = this.userService.user$.subscribe(
      user => {
        if (user) {
          this.userDisplayName = user.name;
        } else {
          this.userDisplayName = undefined;
        }
      });
  }

  public ngOnDestroy():void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public logout(): void {
    this.userService.logout();
    this.router.navigate(['/login']);
  }
}
