import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SettingsService } from '../../../app/core/config/settings.service';
import { IProjectViewModel } from '../../models/project-view.model';

@Injectable({ providedIn: 'root' })
export class BooksHttpService {
    private baseUrl;

    constructor(private client: HttpClient, settingsService: SettingsService) {
        this.baseUrl = `${settingsService.settings.apiHostName}/api/books`;
    }

    public getProjectView(bookPk: number): Observable<IProjectViewModel> {
        return this.client.get<IProjectViewModel>(`${this.baseUrl}/view/${bookPk}`);
    }
}
