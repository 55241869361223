<h1 class="mb-2 mt-2">Buchbestellungen</h1>
<form>
  <div class="row mb-2">
    <div class="col">
      <input name="username" [(ngModel)]="username" (keyup)="searchTermUser$.next($event.target.value)" type="text"
             class="form-control" placeholder="Bestellername">
    </div>
    <div class="col">
      <input name="companyname" [(ngModel)]="companyname" (keyup)="searchTermCompany$.next($event.target.value)"
             type="text"
             class="form-control" placeholder="Bestattername">
    </div>
    <div class="col">
      <select name="month" [(ngModel)]="month" (change)="loadFilteredBookOrders()" class="form-control form-control-lg">
        <option value="1">Januar</option>
        <option value="2">Februar</option>
        <option value="3">März</option>
        <option value="4">April</option>
        <option value="5">Mai</option>
        <option value="6">Juni</option>
        <option value="7">Juli</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">Oktober</option>
        <option value="11">November</option>
        <option value="12">Dezember</option>
      </select>
    </div>
    <div class="col">
      <select name="year" [(ngModel)]="year" (change)="loadFilteredBookOrders()" class="form-control form-control-lg">
        <option *ngFor="let yr of yearRange" value="{{yr}}">{{yr}}</option>
      </select>
    </div>

    <div class="col">
      <select
        name="companyOrigin"
        [(ngModel)]="companyOrigin"
        (change)="loadFilteredBookOrders()"
        class="form-control form-control-lg">
        <option value="all">Gedenkseite egal</option>
        <option value="withoutMemorialPage">ohne Gedenkseite</option>
        <option value="withMemorialPage">mit Gedenkseite</option>
      </select>
    </div>
    <!-- Customer group is filtered by the last three characters of the product id -->
    <div class="col">
      <select
        name="customerGroup"
        [(ngModel)]="productIdEnding"
        (change)="loadFilteredBookOrders()"
        class="form-control form-control-lg">
        <option value="">Kundengruppe egal</option>
        <option value="-02">Bestatter</option>
        <option value="-03">Rapid-Mitarbeiter</option>
        <option value="-01">alle anderen</option>
      </select>
    </div>
  </div>
</form>
<table class="table">
  <thead class="thead-inverse">
  <tr>
    <th colspan="2">Übersicht</th>
    <th class="text-right">Produkt</th>
    <th class="text-right">Menge</th>
    <th class="text-right">Netto</th>
    <th colspan="9" class="text-right">&nbsp;</th>
  </tr>
  <tr class="lighter" *ngFor="let sumByCode of sumsByProductCode">
    <td colspan="3" class="text-right">Produkt-Id {{sumByCode.bookProductId}}:</td>
    <td class="text-right">{{sumByCode.sumOrderedBooks}}</td>
    <td class="text-right">{{sumByCode.sumPositionNetPrice | number:'1.2':'de'}}</td>
    <td colspan="6" class="text-right">&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>
  <tr>
    <td colspan="3" class="text-right">gesamt:</td>
    <td class="text-right">{{sumOrderedBooks}}</td>
    <td class="text-right">{{sumPositionNetPrice | number:'1.2':'de'}}</td>
    <td colspan="6" class="text-right">&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
    <td>&nbsp;</td>
  </tr>
  <tr><td  style="background: transparent; padding: 3px;" colspan="13"></td></tr>
  <tr>
    <th>Datum</th>
    <th>Bestell-ID</th>
    <th>Buch (ID)</th>
    <th class="text-right">Produkt-Id</th>
    <th>Besteller (ID)</th>
    <th>Bestatter</th>
    <th>allcop-Auftrag</th>
    <th class="text-right">Menge</th>
    <th class="text-right">P-Netto</th>
    <th class="text-right">P-Brutto</th>
    <th class="text-right">G-Netto</th>
    <th class="text-right">G-Brutto</th>
    <th class="text-center">Projekt</th>
    <th class="text-center">Status</th>
  </tr>
  </thead>
  <tbody>
    <tr *ngFor="let order of bookOrders" [ngClass]="{'rapid': isRapid(order), 'dependant': isDependant(order), 'unprocessed': isReservationUnprocessed(order)}">
      <td>{{order.orderTimestamp | date: 'dd.MM.yyyy'}}</td>
      <td class="cursor-pointer" (click)="copyToClipboard(order.reservationId)"><small title="{{order.reservationId}} (click to copy)">{{order.reservationId.split('-')[0]}}-...</small></td>
      <td class="description-cell">{{order.bookDescription}} ({{order.bookPk}})</td>
      <td class="text-right">{{order.bookProductId}}</td>
      <td>{{order.userDisplayName}} ({{order.userPk}})</td>
      <td>{{order.companyName}}</td>
      <td>{{order.allcopOrderId}}</td>
      <td class="text-right">{{order.quantity}}</td>
      <td class="text-right">{{order.positionNetPrice | number:'1.2':'de'}}</td>
      <td class="text-right">{{order.positionGrossPrice | number:'1.2':'de'}}</td>
      <td class="text-right">{{order.totalNetPrice | number:'1.2':'de'}}</td>
      <td class="text-right">{{order.totalGrossPrice | number:'1.2':'de'}}</td>
      <td class="text-center">
      <span *ngIf="order.previewLink" style="display: inline-block; min-width: 45px;">
        <a title="Projekt" id="book-order-link" (click)="loadProjectView(order.bookPk)">
          <i class="fa fa-book"></i>
        </a>
      </span>
      </td>
      <td class="text-center">
        <i class="fa" [ngClass]="{'fa-check': isReservationProcessed(order), 'fa-cogs': isReservationInProcess(order), 'fa-exclamation-circle': isReservationUnprocessed(order)}"
        [title]="getOrderStateTooltip(order)"></i>
      </td>
    </tr>
  </tbody>
</table>
