import { Injectable } from '@angular/core';
import { AppSettings } from './app-settings.model';
import { deserialize, plainToClass } from 'class-transformer';
import buildSettingsBase from '../../../assets/config/build/build-settings.json';
import appSettingsBase from '../../../assets/config/base/settings.docker.json';
import appSettingsBaseFromAngular from '../../../assets/config/base/settings.json';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { SettingsService } from './settings.service';
import 'reflect-metadata';
import { BuildSettings } from './build-settings.model';

@Injectable({ providedIn: 'root' })
export class SettingsHttpService {

  public static appSettingsPathDocker = 'assets/config/base/settings.docker.json';

  public static appSettingsPathAngular = 'assets/config/base/settings.json';

  public static buildSettingsPath = 'assets/config/build/build-settings.json';

  constructor(private http: HttpClient, private settingsService: SettingsService) { }

  public static getAppSettingsForJson(): AppSettings {
    const settings = SettingsHttpService.readAppSettingsFromFile();

    if (SettingsHttpService.useLocalConfigSet(settings)) {
      return SettingsHttpService.readAppSettingsFromFile(false);
    }
    return settings;
  }

  /**
 * Reads the buildSettings from file. For docker environments only!
 * Required since some of the metadata references the settings during compilation (no async option).
 */
  public static getBuildSettingsForJson(): BuildSettings {
    return SettingsHttpService.readBuildSettingsFromFile();
  }

  private static readAppSettingsFromFile(useEnvironmentDefault: boolean = true): AppSettings {
    if (useEnvironmentDefault) {
      return plainToClass(AppSettings, appSettingsBase, { enableImplicitConversion: true });
    }
    return plainToClass(AppSettings, appSettingsBaseFromAngular, { enableImplicitConversion: true });
  }

  private static readBuildSettingsFromFile(): BuildSettings {
    return plainToClass(BuildSettings, buildSettingsBase, { enableImplicitConversion: true });
  }

  private static useLocalConfigSet(settings: AppSettings): boolean {
    return SettingsHttpService.settingsNullOrEmpty(settings) || !settings.useEnvironmentVariableConfig;
  }

  private static settingsNullOrEmpty(settings: AppSettings): boolean {
    return !settings || !settings.apiHostName;
  }

  public async initializeApp(): Promise<any> {
    // load build settings => only present for docker contexts!
    await this.http
      .get(SettingsHttpService.buildSettingsPath, { responseType: 'text' })
      .pipe(
        tap((response) => {
          try {
            this.settingsService.buildSettings = deserialize(BuildSettings, response, {
              enableImplicitConversion: true
            });
          } catch (e) {
            this.settingsService.buildSettings = null;
          }
        })
      )
      .toPromise();

    // the settings need to be awaited here first, since they are being loaded for the first time.
    // A simple if/else won't do, because we could only check an empty settings instance.
    const result = await this.http
      .get(SettingsHttpService.appSettingsPathDocker, { responseType: 'text' })
      .pipe(
        tap((response) => {
          try {
            this.settingsService.settings = deserialize(AppSettings, response, { enableImplicitConversion: true });
          } catch (e) {
            this.settingsService.settings = null;
          }
        })
      )
      .toPromise();

    if (SettingsHttpService.useLocalConfigSet(this.settingsService.settings)) {
      return this.http
        .get(SettingsHttpService.appSettingsPathAngular, { responseType: 'text' })
        .pipe(
          tap(
            (response) => {
              this.settingsService.settings = deserialize(AppSettings, response, { enableImplicitConversion: true });
            }
          )
        )
        .toPromise();
    }

    return result;
  }
}
