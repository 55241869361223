import {Injectable} from '@angular/core';
import {UserDataService} from './user-data.service';
import {TokenStoreService} from '../token-store.service';
import {HttpClient} from '@angular/common/http';
import { SettingsService } from '../../../app/core/config/settings.service';

@Injectable()
export class UserDataHttpService extends UserDataService {
  private authBaseUrl;

  constructor(protected tokenStore: TokenStoreService,
              private client: HttpClient, settingsService: SettingsService) {
    super(tokenStore);
    this.authBaseUrl = `${settingsService.settings.apiHostName}/api/auth/`;
  }

  public login(userName: string,
               password: string): void {
    const serviceUrl: string = this.authBaseUrl + `login`;
    this.client.post<any>(serviceUrl, {userName: userName, password: password})
      .subscribe(
        response => {
          this.tokenStore.saveToken(response.token);
          this.setUser(this.tokenStore.getUserFromToken());
        });
  }

  public logout(): void {
    this.tokenStore.removeToken();
    this.setUser(undefined);
  }

}
