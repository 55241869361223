<ebd-header-bar></ebd-header-bar>
<div class="app-body">
  <div class="sidebar">
    <nav class="sidebar-nav">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/buchbestellungen']"><i
            class="icon-speedometer"></i>
            Buchbestellungen
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/provision']"><i
            class="icon-speedometer"></i>
            Provisionen
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/sammelrechnung']"><i
            class="icon-speedometer"></i>
            Sammelrechnung
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/sammelabrechner']"><i
            class="icon-speedometer"></i>
            Sammelabrechner
          </a>
        </li>
      </ul>
    </nav>
  </div>

  <main class="main">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<footer class="app-footer footer">
  <a href="http://admin.das-erinnerungsbuch.de">Erinnerungsbuch-Dashboard</a> &copy; {{currentYear}}
  <a
    href="http://www.rapid-data.de" target="_blank">Rapid Data GmbH.
  </a>
</footer>
