import {HttpClient} from '@angular/common/http';
import {TokenStoreService} from '../token-store.service';
import {UserDataService} from './user-data.service';
import {UserDataHttpService} from './user-data-http.service';
import { SettingsService } from '../../../app/core/config/settings.service';

export function userDataServiceFactory(tokenStore: TokenStoreService,
                                       client: HttpClient, settingService: SettingsService) {
  return new UserDataHttpService(tokenStore, client, settingService);
}

export const userDataServiceProvider = {
  provide: UserDataService,
  useFactory: userDataServiceFactory,
  deps: [TokenStoreService, HttpClient, SettingsService]
};
