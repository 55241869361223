import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {TokenStoreService} from './token-store.service';

@Injectable()
export class AuthHeaderInterceptorService implements HttpInterceptor {

  constructor(private tokenStore: TokenStoreService) {
  }

  public intercept(req: HttpRequest<any>,
                   next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenStore.loadToken();
    if (!token) {
      return next.handle(req);
    }

    const authHeader = `Bearer ${token}`;
    const authReq = req.clone({headers: req.headers.set('Authorization', authHeader)});

    return next.handle(authReq);
  }
}
