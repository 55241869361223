import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { filter, first, map } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';

import { ActionDescriptor, ActionTypes } from '../../models/action-descriptor';
import { InvoiceableBookModel } from '../../models/invoiceable-book.model';
import { Reservation } from '../../models/reservation.model';

import { BookOrderDataService } from '../../services/book-order-data/book-order-data.service';

@Component({
  selector: 'ebd-invoiceable-books',
  templateUrl: './invoiceable-books.component.html',
  styleUrls: ['./invoiceable-books.component.scss']
})
export class InvoiceableBooksComponent implements OnInit, OnDestroy {

  /**
   * Contains the morticians, of whom one or more books are displayed within the current time filter.
   */
  public selectableMorticians: string[];

  /**
   * Contains the list of distinct {@link Reservation}s from {@link loadedBooks}.
   */
  public reservations: Reservation[];

  /**
   * Contains the list of books to be displayed.
   * @private
   */
  public invoiceableBooks: InvoiceableBookModel[] = [];

  /**
   * Contains the start- and end-date of the currently selected time range.
   */
  private _dateRange: Date[];

  /**
   * Contains the name of the mortician, which is selected for filtering.
   */
  private _selectedMortician: string;

  /**
   * Contains the list of books which are loaded via {@link dataService}.
   */
  private loadedBooks: InvoiceableBookModel[] = [];

  /**
   * Contains the subscription to the {@link dataService}s completedAction.
   */
  private dataSubscription: Subscription;

  constructor(
    private dataService: BookOrderDataService) {

    const now: Date = new Date();
    const currentMonth = now.getMonth();
    let year = now.getFullYear();
    const lastMonth = (currentMonth + 11) % 12;
    if (currentMonth === 0) { --year; }

    const firstOfLastMonth = new Date(year, lastMonth, 1);
    const lastOfLastMonth = new Date(year, currentMonth, 0);   // the 0th of August automatically becomes 31st of Juli
    this._dateRange = [firstOfLastMonth, lastOfLastMonth];
  }

  /**
   * Getter for {@link _dateRange}.
   * @returns {Date[]}
   */
  public get dateRange(): Date[] {
    return this._dateRange;
  }

  /**
   * Setter for {@link _dateRange}. Triggers a reload of books with the modified time range.
   * @param {Date[]} value: The new value for the filtering time range.
   */
  public set dateRange(
    value: Date[]) {
    this._dateRange = value;
    this.loadBooks();
  }

  /**
   * Getter for {@link _selectedMortician}.
   * @returns {string}
   */
  public get selectedMortician(): string {
    return this._selectedMortician;
  }

  /**
   * Setter for {@link _selectedMortician}. Triggers a reload of books with the modified selected mortician.
   * @param {string} value: The new value for the selected mortician.
   */
  public set selectedMortician(
    value: string) {
    this._selectedMortician = value;
    this.filterBooks();
  }

  /**
   * Sets up the handling for un-/successful loading of invoiceable books.
   */
  public ngOnInit(): void {
    this.dataSubscription = this.dataService.completedAction$.pipe(
      filter((action: ActionDescriptor) => action.actionType === ActionTypes.LoadInvoiceableBooks),
      map((action: ActionDescriptor) => action.payload)
    ).subscribe((books: InvoiceableBookModel[]) => {
      this.loadedBooks = [...books];
      this.filterBooks();

      this.updateSelectableMorticians();
    });

    this.dataService.failedAction$.pipe(
      filter((action: ActionDescriptor) => action.actionType === ActionTypes.LoadInvoiceableBooks),
      first(),
      map((action: ActionDescriptor) => action.payload)
    ).subscribe((error: HttpErrorResponse) => {
      alert('Fehler beim Laden der Sammelrechnungen:\r\n' + error.message || error);
    });

    this.loadBooks();
  }

  /**
   * Unsubscribes from subscriptions - if any.
   */
  public ngOnDestroy(): void {
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  /**
   * Filters the {@link reservations} by their morticianName.
   * @param {string} morticianName: the name to use as the filter.
   * @returns {Reservation[]}: The filtered list.
   */
  public filterReservationsByMortician(
    morticianName: string): Reservation[] {
    if (!morticianName) {
      return this.reservations;
    }

    return this.reservations.filter((r: Reservation) => r.morticianName === morticianName);
  }

  /**
   * Filters the {@link invoiceableBooks} their reservationId.
   * @param {string} reservationId: The id to use as the filter.
   * @returns {InvoiceableBookModel[]}: the filtered list.
   */
  public filterBooksByReservation(
    reservationId: string): InvoiceableBookModel[] {
    if (!reservationId) {
      return this.invoiceableBooks;
    }

    return this.invoiceableBooks.filter((b: InvoiceableBookModel) => b.reservationId === reservationId);
  }

  /**
   * Sets the #selectableMorticians from the {@link loadedBooks}.
   */
  private updateSelectableMorticians() {
    const customerNames: string[] = this.loadedBooks.map(b => b.customerName);
    this.selectableMorticians = customerNames
      .filter((name: string, index: number) => index === customerNames.indexOf(name))
      .sort();
  }

  /**
   * Loads the invoiceable books via the {@link dataService}.
   */
  private loadBooks(): void {
    let minDate: Date = null;
    let maxDate: Date = null;

    if (this._dateRange && (this._dateRange.length === 2)) {
      minDate = this.dateRange[0];
      maxDate = this.dateRange[1];
    }

    this.dataService.loadInvoiceableBooks(minDate, maxDate);
  }

  /**
   * Filters the {@link loadedBooks} by {@link selectedMortician}.
   */
  private filterBooks() {
    this.invoiceableBooks = [...this.loadedBooks];
    if (this.selectedMortician) {
      const filteredBooks = this.loadedBooks
        .filter((b: InvoiceableBookModel) => b.customerName === this.selectedMortician);

      this.invoiceableBooks = [...filteredBooks];
    }

    this.reservations = this.invoiceableBooks
      .filter(
        (book: InvoiceableBookModel, bookIndex: number) => this.invoiceableBooks.findIndex(
          (book2: InvoiceableBookModel) => book2.reservationId === book.reservationId) === bookIndex)
      .map((book: InvoiceableBookModel) => ({
        id: book.reservationId,
        morticianName: book.customerName,
        allcopOrderId: book.allcopOrderId,
        date: book.orderTimestamp,
        totalNetPrice: book.totalNetPrice,
        totalGrossPrice: book.totalGrossPrice,
        shipping: book.shipping
      }));
  }
}
