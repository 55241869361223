import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/Observable';
import { take } from 'rxjs/operators';
import { CustomerModel } from '../../../models/customer-model';
import { CustomersService } from '../../../services/customers/customers.service';

@Component({
  selector: 'ebd-customers-page',
  templateUrl: './customers-page.component.html',
  styleUrls: ['./customers-page.component.scss']
})
export class CustomersPageComponent implements OnInit {

  public customers$: Observable<CustomerModel[]>;

  public customer$: Observable<CustomerModel>;

  public isEditing = false;

  constructor(private customerService: CustomersService) {
    this.customers$ = this.customerService.loadCustomers();
  }

  ngOnInit(): void {
  }

  public startEditCustomer(customerPk: number): void {
    this.isEditing = true;
    this.customer$ = this.customerService.loadCustomer(customerPk)
                         .pipe(take(1));
  }

  public finishEditCustomer(customer: CustomerModel): void {
    if (customer.pk) {
      this.customerService.updateCustomer(customer)
          .pipe(take(1))
          .subscribe(() => {
            this.customers$ = this.customerService.loadCustomers();
            this.isEditing = false;
          });
    } else {
      this.customerService.createCustomer(customer)
          .pipe(take(1))
          .subscribe(() => {
            this.customers$ = this.customerService.loadCustomers();
            this.isEditing = false;
          });
    }
  }

  public startCreateCustomer(): void {
    this.isEditing = true;
    this.customer$ = of({} as CustomerModel);
  }

  public cancelEditCustomer(): void {
    this.isEditing = false;
  }

  public deleteCustomer(customer: CustomerModel): void {
    if (!confirm(`Soll der Sammelabrechner ${customer.name} gelöscht werden?`)) {
      return;
    }
    this.customerService.deleteCustomer(customer.pk)
        .pipe(take(1))
        .subscribe(() => this.customers$ = this.customerService.loadCustomers());
  }
}
