import {Injectable} from '@angular/core';
import { Subject } from 'rxjs';
import {IUserModel, UserModel} from '../../models/user.model';
import {TokenStoreService} from '../token-store.service';

@Injectable()
export abstract class UserDataService {
  public user$: Subject<IUserModel> = <Subject<IUserModel>>new Subject();
  public loginReturnUrl: string;
  private user: IUserModel;

  constructor(protected tokenStore: TokenStoreService) {
  }


  public getUser() {
    return Object.assign(UserModel.empty, this.user);
  }

  public checkAuthentication(): void {
    if (!this.user) {
      const token = this.tokenStore.loadToken();
      if (token) {
        const userFromToken = this.tokenStore.getUserFromToken();
        if (userFromToken.sub) {
          this.setUser(userFromToken);
        }
      }
    }
  }

  public abstract login(userName: string,
                        password: string,
                        rememberMe: boolean): void;

  public abstract logout(): void;

  protected setUser(value: IUserModel) {
    if (value) {
      this.user = Object.assign(UserModel.empty, value);
      window.localStorage.setItem('user', JSON.stringify(this.user));
    } else {
      this.user = undefined;
      window.localStorage.removeItem('user');
    }
    this.user$.next(Object.assign(UserModel.empty, this.user));
  }

}
