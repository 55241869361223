import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BookOrdersComponent } from './components/book-orders/book-orders.component';
import { CustomersPageComponent } from './components/customers/customers-page/customers-page.component';
import { InvoiceableBooksComponent } from './components/invoiceable-books/invoiceable-books.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProvisionedBooksComponent } from './components/provisioned-books/provisioned-books.component';

import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [AuthGuardService],
    children: [
      {path: '', pathMatch: 'full', redirectTo: '/buchbestellungen'},
      {path: 'buchbestellungen', component: BookOrdersComponent },
      {path: 'provision', component: ProvisionedBooksComponent },
      {path: 'sammelrechnung', component: InvoiceableBooksComponent },
      {path: 'sammelabrechner', component: CustomersPageComponent }
    ]
  },
  {path: 'login', component: LoginComponent},
  {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
