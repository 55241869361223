import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import { CustomerModel } from '../../../models/customer-model';

@Component({
  selector: 'ebd-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit, OnDestroy {

  @Input()
  public customer$: Observable<CustomerModel>;

  @Output()
  public saved: EventEmitter<CustomerModel> = new EventEmitter<CustomerModel>();

  @Output()
  public cancelled: EventEmitter<void> = new EventEmitter<void>();

  private customerPk: number;

  private shutdown$: Subject<void> = new Subject<void>();

  public form: FormGroup;

  constructor(private formBuilder: FormBuilder) {
  }

  public ngOnInit(): void {
    this.customer$
        .pipe(
          filter(c => !!c),
          takeUntil(this.shutdown$)
        )
        .subscribe((customer: CustomerModel) => {
          this.customerPk = customer.pk;
          this.form = this.formBuilder.group({
            rapidId: [customer.rapidId, {validators: [Validators.required, Validators.maxLength(6)]}],
            debtorId: [customer.debtorId, {validators: [Validators.required, Validators.maxLength(20)]}],
            billingType: [customer.billingType, {validators: [Validators.required]}],
            salutation: [customer.salutation, {validators: [Validators.required]}],
            name: [customer.name, {validators: [Validators.required, Validators.maxLength(250)]}],
            firstNames: [customer.firstNames, {validators: [Validators.required, Validators.maxLength(150)]}],
            lastName: [customer.lastName, {validators: [Validators.required, Validators.maxLength(150)]}],
            email: [customer.email, {validators: [Validators.required, Validators.maxLength(320), Validators.email]}],
            phone: [customer.phone, {validators: [Validators.required, Validators.maxLength(50)]}],
            street: [customer.street, {validators: [Validators.required, Validators.maxLength(100)]}],
            streetNumber: [customer.streetNumber, {validators: [Validators.required, Validators.maxLength(10)]}],
            zipCode: [customer.zipCode, {validators: [Validators.required, Validators.maxLength(10)]}],
            town: [customer.town, {validators: [Validators.required, Validators.maxLength(100)]}],
            country: [customer.country, {validators: [Validators.required]}]
          });
        });
  }

  public ngOnDestroy(): void {
    this.shutdown$.next();
  }

  public saveChanges(): void {

    if (!this.form.valid) {
      return;
    }

    const customer = {pk: this.customerPk, ...this.form.getRawValue()} as CustomerModel;
    this.saved.emit(customer);
  }

  public cancelEditing(): void {
    this.cancelled.emit();
  }
}
