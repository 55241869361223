import {Injectable} from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BusyService {
  // public busyMessage$: Subject<string> = <Subject<string>>new Subject();
  public switch$: Subject<boolean> = <Subject<boolean>>new Subject();

  constructor() {
  }

  public show(message?: string) {
    this.switch$.next(true);
    // this.busyMessage$.next(message);
  }

  public hide() {
    // this.busyMessage$.next('');
    this.switch$.next(false);
  }
}
