import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {UserDataService} from './user-data/user-data.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {
  constructor(private userService: UserDataService,
              private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot,
                     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.userService.checkAuthentication();

    const user = this.userService.getUser();
    const isAuthenticated = !!user.name;
    if (isAuthenticated) {
      return true;
    }

    this.userService.loginReturnUrl = state.url;
    this.router.navigate(['/login']);

    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }
}
