import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BookOrdersComponent } from './components/book-orders/book-orders.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ProvisionedBooksComponent } from './components/provisioned-books/provisioned-books.component';
import { EbdErrorHandler } from './core/EbdErrorHandler';

import { AuthGuardService } from './services/auth-guard.service';
import { AuthHeaderInterceptorService } from './services/auth-header-interceptor.service';
import { bookOrderDataServiceProvider } from './services/book-order-data/book-order-data.service.provider';
import { BusyService } from './services/busy.service';
import { TokenStoreService } from './services/token-store.service';
import { userDataServiceProvider } from './services/user-data/user-data-service.provider';
import { InvoiceableBooksComponent } from './components/invoiceable-books/invoiceable-books.component';
import { DateRangeSelectorComponent } from './components/date-range-selector/date-range-selector.component';
import { MorticianSelectorComponent } from './components/mortician-selector/mortician-selector.component';
import { appSettingsFactory } from './core/config/settings.factory';
import { SettingsHttpService } from './core/config/settings.http.service';
import { CustomersPageComponent } from './components/customers/customers-page/customers-page.component';
import { CustomersListComponent } from './components/customers/customers-list/customers-list.component';
import { CustomerFormComponent } from './components/customers/customer-form/customer-form.component';
import { StringValidationComponent } from './components/string-validation/string-validation.component';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    BookOrdersComponent,
    HeaderBarComponent,
    LayoutComponent,
    LoginComponent,
    NotFoundComponent,
    ProvisionedBooksComponent,
    InvoiceableBooksComponent,
    DateRangeSelectorComponent,
    MorticianSelectorComponent,
    CustomersPageComponent,
    CustomersListComponent,
    CustomerFormComponent,
    StringValidationComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TooltipModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: APP_INITIALIZER, useFactory: appSettingsFactory, deps: [SettingsHttpService], multi: true },
    AuthGuardService,
    BusyService,
    bookOrderDataServiceProvider,
    TokenStoreService,
    userDataServiceProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptorService,
      multi: true
    }, {
      provide: ErrorHandler, useClass: EbdErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
