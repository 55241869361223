import { Expose, Transform } from 'class-transformer';

export class BuildSettings {

  @Expose({ name: 'BUILD_NUMBER' })
  @Transform(({ value }) => {
    const parsed = Number.parseInt(value, 10);
    const isInt = Number.isInteger(parsed) && parsed >= 0;
    if (isInt) {
      return parsed;
    }
    return -1;
  })
  public buildNumber: number;
}
