<h1 class="mb-2 mt-2">Sammelrechnungen</h1>

<form>
  <div class="row mb-2">
    <div class="col">
      <ebd-date-range-selector [(dateRange)]="dateRange"></ebd-date-range-selector>
    </div>
    <div class="col">
      <ebd-mortician-selector [selectableMorticians]="selectableMorticians" [(selectedMortician)]="selectedMortician"></ebd-mortician-selector>
    </div>
  </div>
</form>

<div>
  <div *ngFor="let morticianName of selectableMorticians">
    <div *ngIf="!selectedMortician || (morticianName === selectedMortician)" class="mortician-container">
      <div class="mortician-header">{{morticianName}}</div>
      <div *ngFor="let reservation of filterReservationsByMortician(morticianName)" class="reservation-container">
        <div class="reservation-header">
          <div class="reservation-date">Bestellung {{reservation.allcopOrderId}} vom {{reservation.date | date:'dd.MM.yyyy'}}</div>
          <div class="reservation-price">
            <span class="netto-price">netto {{reservation.totalNetPrice | currency:'EUR':true}} /</span>
            brutto {{reservation.totalGrossPrice | currency:'EUR':true}}
          </div>
        </div>
        <div *ngFor="let book of filterBooksByReservation(reservation.id)" class="reservation-row">
          <div class="reservation-column reservation-description">{{book.bookDescription}}, {{book.pageCount}} Seiten</div>
          <div class="reservation-column reservation-quantity">{{book.quantity}}</div>
          <div class="reservation-column reservation-price">{{book.positionSingleGrossPrice | currency:'EUR':true}}</div>
          <div class="reservation-column reservation-price">{{book.positionTotalGrossPrice | currency:'EUR':true}}</div>
        </div>
        <div class="reservation-row">
          <div class="reservation-column reservation-description">Versand</div>
          <div class="reservation-column reservation-quantity"></div>
          <div class="reservation-column reservation-price"></div>
          <div class="reservation-column reservation-price">{{reservation.shipping | currency:'EUR':true}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
