<div class="app flex-row align-items-center">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card-group mb-0">
          <div class="card p-2">
            <form (ngSubmit)="login()">
              <div class="card-block">
                <h1>Anmelden</h1>
                <p class="text-muted">Melde dich mit deinen Zugangsdaten an.</p>
                <div class="input-group mb-1">
                  <span class="input-group-addon"><i class="icon-user"></i></span>
                  <input type="text" class="form-control" placeholder="Anmeldename" [(ngModel)]="userName"
                         name="userName"
                         required>
                </div>
                <div class="input-group mb-2">
                  <span class="input-group-addon"><i class="icon-lock"></i></span>
                  <input type="password" class="form-control" placeholder="Kennwort" [(ngModel)]="password"
                         name="password" required>
                </div>
                <div class="row">
                  <div class="col-6">
                    <button type="submit" class="btn btn-primary px-2">Anmelden</button>
                  </div>
                </div>
                <div *ngIf="showFailure" class="row">
                  <div class="col-12">
                    <p class="text-danger">Die Anmeldung ist fehlgeschlagen. Benutzername oder Kennwort (oder beides)
                      stimmen nicht.</p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
