import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ebd-mortician-selector',
  templateUrl: './mortician-selector.component.html',
  styleUrls: ['./mortician-selector.component.scss']
})
export class MorticianSelectorComponent {

  /**
   * Contains the morticians, of whom one or more books are displayed with in the current time filter.
   */
  @Input()
  public selectableMorticians: string[];

  @Output()
  public selectedMorticianChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Contains the name of the mortician, which is selected for filtering.
   */
  private _selectedMortician: string;

  constructor() {
  }

  /**
   * Getter for {@link _selectedMortician}.
   * @returns {string}
   */
  public get selectedMortician(): string {
    return this._selectedMortician;
  }

  /**
   * Setter for {@link _selectedMortician}. Triggers a reload of books with the modified selected mortician.
   * @param {string} value: The new value for the selected mortician.
   */
  @Input()
  public set selectedMortician(
    value: string) {
    this._selectedMortician = value;
    this.selectedMorticianChange.emit(this._selectedMortician);
  }
}
