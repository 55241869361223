import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {IUserModel, UserModel} from '../models/user.model';

@Injectable()
export class TokenStoreService {

  private jwtHelperService: JwtHelperService;

  constructor() {
    this.jwtHelperService = new JwtHelperService();
  }

  public saveToken(token: string): void {
    window.localStorage.setItem('token', token);
  }

  public loadToken(): string {
    return window.localStorage.getItem('token');
  }

  public removeToken() {
    window.localStorage.removeItem('token');
  }

  public getUserFromToken(): IUserModel {
    const token: string = this.loadToken();

    return <IUserModel>Object.assign(UserModel.empty, this.jwtHelperService.decodeToken(token));
  }
}
