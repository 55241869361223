import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { ActionDescriptor } from '../../models/action-descriptor';
import { IBookOrderModel } from '../../models/book-order.model';
import { ProvisionedBookModel } from '../../models/provisioned-book.model';

@Injectable()
export abstract class BookOrderDataService {
  public bookOrders$: Subject<IBookOrderModel[]> = new Subject<IBookOrderModel[]>();
  public provisionedBooks$: Subject<ProvisionedBookModel[]> = new Subject<ProvisionedBookModel[]>();
  public completedAction$: Subject<ActionDescriptor> = new Subject<ActionDescriptor>();
  public failedAction$: Subject<ActionDescriptor> = new Subject<ActionDescriptor>();

  public abstract loadBookOrders(
    year: number,
    month: number,
    username?: string,
    companyname?: string,
    companyOrigin?: string): void;

  public abstract loadProvisionedBooks(
    minDate?: Date,
    maxDate?: Date): void;

  public abstract changeSettlement(
    reservationItemId: number,
    isSettled: boolean): void;

  public abstract loadInvoiceableBooks(
    minDate?: Date,
    maxDate?: Date): void;
}
