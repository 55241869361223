<h1 class="mb-2 mt-2">Provisionen</h1>

<form>
  <div class="row mb-2">
    <div class="col">
      <ebd-date-range-selector [(dateRange)]="dateRange"></ebd-date-range-selector>
    </div>
    <div class="col">
      <ebd-mortician-selector [selectableMorticians]="selectableMorticians" [(selectedMortician)]="selectedMortician"></ebd-mortician-selector>
    </div>
  </div>
</form>

<table class="table">
  <thead class="thead-inverse">
  <tr>
    <th>Datum</th>
    <th>Besttatter-ID</th>
    <th>Besttatter</th>
    <th>Produkt-Id</th>
    <th>Buch(Bestell-ID)</th>
    <th>Besteller</th>
    <th>Besteller-Typ</th>
    <th class="text-right">Menge</th>
    <th class="text-right">Netto</th>
    <th class="text-right">Provision</th>
    <th></th>
  </tr>
  </thead>

  <tbody>
  <tr *ngFor="let book of provisionedBooks">
    <td>{{book.orderDate | date: 'dd.MM.yyyy'}}</td>
    <td>{{book.rapidId}}</td>
    <td>{{book.morticianName}}</td>
    <td>{{book.productId}}</td>
    <td>{{book.bookDescription}} ({{book.pk}})</td>
    <td>{{book.customerName}}</td>
    <td>{{book.customerType}}</td>
    <td class="text-right">{{book.quantity}}</td>
    <td class="text-right">{{book.netTotal | number:'1.2':'de'}}</td>
    <td class="text-right">{{book.provision | number:'1.2':'de'}}</td>
    <td>
      <input type="checkbox" [(ngModel)]="book.isSettled" (change)="updateProvisionSettlement(book)">
    </td>
  </tr>
  </tbody>

  <tfoot class="thead-inverse">
  <tr style="">
    <td colspan="8" class="text-right">Summe:</td>
    <td class="text-right">{{sumNetTotal | number:'1.2':'de'}}</td>
    <td class="text-right">{{sumProvision | number:'1.2':'de'}}</td>
    <td></td>
  </tr>
  </tfoot>
 </table>
