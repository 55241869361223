<div class="form-container">
  <h2 class="mb-2 mt-2">Sammelabrechner bearbeiten</h2>
  <div *ngIf="form" [formGroup]="form" class="form">
    <div class="from-row">
      <label for="rapidId">Rapid ID</label>
      <input id="rapidId" type="text" formControlName="rapidId">
      <div></div>
      <ebd-string-validation [control]="form.get('rapidId')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="debtorId">Debitoren-Nummer</label>
      <input id="debtorId" type="text" formControlName="debtorId">
      <div></div>
      <ebd-string-validation [control]="form.get('debtorId')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="billingType">Abrechnung</label>
      <select id="billingType" formControlName="billingType">
        <option value="Allcop">Allcop</option>
        <option value="Rapid">Rapid</option>
      </select>
      <div></div>
      <ebd-string-validation [control]="form.get('billingType')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="name">Firma</label>
      <input id="name" type="text" formControlName="name">
      <div></div>
      <ebd-string-validation [control]="form.get('name')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="salutation">Anrede</label>
      <select id="salutation" formControlName="salutation">
        <option value="Female">Frau</option>
        <option value="Male">Herr</option>
      </select>
      <div></div>
      <ebd-string-validation [control]="form.get('salutation')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="firstNames">Vornamen</label>
      <input id="firstNames" type="text" formControlName="firstNames">
      <div></div>
      <ebd-string-validation [control]="form.get('firstNames')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="lastName">Nachname</label>
      <input id="lastName" type="text" formControlName="lastName">
      <div></div>
      <ebd-string-validation [control]="form.get('lastName')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="email">E-Mail-Adresse</label>
      <input id="email" type="text" formControlName="email">
      <div></div>
      <ebd-string-validation [control]="form.get('email')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="phone">Telefon-Nummer</label>
      <input id="phone" type="text" formControlName="phone">
      <div></div>
      <ebd-string-validation [control]="form.get('phone')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="street">Straße</label>
      <input id="street" type="text" formControlName="street">
      <div></div>
      <ebd-string-validation [control]="form.get('street')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="streetNumber">Hausnummer</label>
      <input id="streetNumber" type="text" formControlName="streetNumber">
      <div></div>
      <ebd-string-validation [control]="form.get('streetNumber')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="zipCode">PLZ</label>
      <input id="zipCode" type="text" formControlName="zipCode">
      <div></div>
      <ebd-string-validation [control]="form.get('zipCode')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="town">Ort</label>
      <input id="town" type="text" formControlName="town">
      <div></div>
      <ebd-string-validation [control]="form.get('town')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <label for="country">Land</label>
      <select id="country" formControlName="country">
        <option value="DE">Deutschland</option>
        <option value="AT">Österreich</option>
        <option value="CH">Schweiz</option>
      </select>
      <div></div>
      <ebd-string-validation [control]="form.get('country')"></ebd-string-validation>
    </div>
    <div class="from-row">
      <div></div>
      <div class="actions-container">
        <input class="action-button" type="button" value=" Speichern " (click)="saveChanges()" [disabled]="!form.valid">
        <input class="action-button" type="button" value=" Abbrechen " (click)="cancelEditing()">
      </div>
    </div>
  </div>
</div>
