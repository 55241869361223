import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'ebd-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  public currentYear: number;

  constructor() {
    this.currentYear = new Date().getFullYear();
  }

  public ngOnInit(): void {
  }

}
