import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { SettingsService } from '../../core/config/settings.service';
import { CustomerModel } from '../../models/customer-model';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  private baseUrl: string;

  constructor(
    private client: HttpClient,
    settingsService: SettingsService) {
    this.baseUrl = `${settingsService.settings.apiHostName}/api/customers`;
  }

  public loadCustomers(): Observable<CustomerModel[]> {
    const url = this.baseUrl;
    return this.client.get<CustomerModel[]>(url);
  }

  public loadCustomer(customerPk: number): Observable<CustomerModel> {
    const url = `${this.baseUrl}/${customerPk}`;
    return this.client.get<CustomerModel>(url);
  }

  public createCustomer(customer: CustomerModel): Observable<number> {
    const url = this.baseUrl;
    return this.client.post<number>(url, customer);
  }

  public updateCustomer(customer: CustomerModel): Observable<void> {
    const url = `${this.baseUrl}/${customer.pk}`;
    return this.client.put<void>(url, customer);
  }

  public deleteCustomer(customerPk: number): Observable<void> {
    const url = `${this.baseUrl}/${customerPk}`;
    return this.client.delete<void>(url);
  }
}
