import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';

export class EbdErrorHandler implements ErrorHandler {
  public handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      alert(error.error.Message || error.error || error || 'Es ist irgendsoein Drecksfehler aufgetreten.');
    }
  }
}
