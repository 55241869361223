import { Expose, Transform } from 'class-transformer';

export class AppSettings {
    @Expose({ name: 'PRODUCTION' })
    @Transform(({ value }) => {
      switch (value) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return value;
      }
    })
    public production: boolean;


    @Expose({ name: 'API_HOST_NAME' })
    public apiHostName: string;

    @Expose({ name: 'USE_ENVIRONMENT_VARIABLE_CONFIG' })
    @Transform(({ value }) => {
      switch (value) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return value;
      }
    })
    public useEnvironmentVariableConfig: boolean;
}
